<template>
	<div class="privacy-app">
		<div class="view-header">
			<logo-zionesport class="view-header-logo" :class="$responsive"/>
			<h1 class="title">
				Política de privacidad
			</h1>
			<p class="view-header__description">
				Ponemos a tu disposición nuestro aviso de privacidad de la aplicación zione.sport
			</p>
		</div>
		<!-- content -->
		<section-handler :sections="sections"></section-handler>
	</div>
</template>

<script>
	import content from "@/content.json";
	import sectionHandler from "@/components/SectionHandler";
	import logoZionesport from "@/components/LogoZionesportH.vue";

	export default {
		name: "PrivacyApp",
		components: {
			sectionHandler,
			logoZionesport,
		},
		data() {
			return {
				sections: content.privacyApp
			}
		}
	}
</script>

<style scoped>
.view-header-logo {
	color: #444;
	display: inline-block;
	width: 300px;
	margin: 1.25rem 0 2rem;
}

.view-header-logo.mobile {
	width: 80vw;
	margin: 1rem 0;
}
</style>